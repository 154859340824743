var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "제안 의견" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: "",
                              editable: false,
                              label: "제목",
                              name: "boardTitle",
                            },
                            model: {
                              value: _vm.data.boardTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "boardTitle", $$v)
                              },
                              expression: "data.boardTitle",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: "",
                              editable: false,
                              label: "의견게시일",
                              name: "boardDate",
                            },
                            model: {
                              value: _vm.data.boardDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "boardDate", $$v)
                              },
                              expression: "data.boardDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: "",
                              editable: false,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              editable: false,
                              type: "dept_user",
                              label: "작성자",
                              name: "regUserId",
                            },
                            model: {
                              value: _vm.data.regUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "regUserId", $$v)
                              },
                              expression: "data.regUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              required: "",
                              editable: false,
                              rows: 8,
                              label: "의견",
                              name: "boardContents",
                            },
                            model: {
                              value: _vm.data.boardContents,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "boardContents", $$v)
                              },
                              expression: "data.boardContents",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-upload", {
                            attrs: {
                              attachInfo: _vm.attachInfoRequest,
                              editable: false,
                              label: "제안의견 첨부파일",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBL0010189", bgClass: "orange-2" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave2,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "처리결과 저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData2,
                                    btnCallback: _vm.saveCallback2,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: _vm.editable,
                              codeGroupCd: "SOP_BOARD_RESULT_CD",
                              itemText: "codeName",
                              itemValue: "code",
                              type: "edit",
                              name: "resultCd",
                              label: "처리결과",
                            },
                            model: {
                              value: _vm.data.resultCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "resultCd", $$v)
                              },
                              expression: "data.resultCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10",
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              rows: 8,
                              label: "LBL0010190",
                              name: "boardResultContent",
                            },
                            model: {
                              value: _vm.data.boardResultContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "boardResultContent", $$v)
                              },
                              expression: "data.boardResultContent",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm-12 col-md-6 col-lg-6" },
                        [
                          _c("c-upload", {
                            attrs: {
                              attachInfo: _vm.attachInfo,
                              editable: _vm.editable,
                              label: "의견결과 첨부파일",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }